const moduleFederationURLSuffix = `_next/static/chunks/remoteEntry.js?${Date.now()}`;

const bustingVariables = {
  buildModuleFederationURL: `${process.env.REACT_APP_BUILD_FRONTEND_URL}/${moduleFederationURLSuffix}`,
  investModuleFederationURL: `${process.env.REACT_APP_INVEST_FRONTEND_URL}/${moduleFederationURLSuffix}`,
};

Object.keys(bustingVariables).forEach(
  (key) => ((<any>window)[key] = (<any>bustingVariables)[key])
);

// eslint-disable-next-line import/extensions
import('./bootstrap.js');
export {};
